.contact {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    align-items: center;
}

.contact {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
 }

.container.contact_container{
    /* padding: 2rem; */
    /* background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px; */
    /* width: 70%; */
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact_options{
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: fit-content;
}

.contact_option{
    background: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact_option-icon{
    font-size: 1.5 rem;
    /* margin-bottom: 0.5rem; */
}

.contact_option a{
    margin-top: 0.7rem;
    /* display: inline-block; */
    /* font-size: 0.8rem */
}

/* =============== Form =============== */
form{
    display:flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    /* background: transparent; */
    border: 2px solid var(--color-primary-variant);
    resize: none;
}