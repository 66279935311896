nav {
  /* background: rgba(0, 0, 0, 0.8); */
  border-radius: 30px 30px 30px 30px;
  display: flex;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 2%;
  top: 50%; /* Change this to 50% to move the navbar to the middle of the page vertically */
  transform: translateY(-50%); /* This will center the navbar vertically */
  flex-direction: column; /* This will align the items vertically */
  gap: 5rem;
  justify-content: center;
  /* max-height: 100vh; */
  font-size: 1.5rem;
}

nav>a:hover {
  width: 150px;
  background-color: rgba(0, 132, 255, 0.5);
  border-radius: 10px 10px 10px 10px;
  position: relative;
  transition: 0.5s;
}

nav span {
  font-family: "Raleway", sans-serzzif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  bottom: 0;
  left: 47px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0;
  opacity: 0;
  transition: width 0.3s, opacity 0.2s;
  left: 50%;
  transform: translateX(-50%);
  /* Center the text horizontally over the icon */
}

nav a:hover span {
  overflow: visible;
  width: auto;
  padding: 7px 14px;
  opacity: 1;
}

nav a:hover svg {
  opacity: 0;
}

/* ==== Media Queries (Small Devices) ==== */
@media screen and (max-width: 850px) {
  nav {
    display: flex;
    left: 0; /* Change this from top to left */
    border-radius: 10px 10px 10px 10px;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    padding-left: 5%;
    padding-right: 5%;
  }

  nav>a:hover {
    /* width: 150px; */
    /* background-color: rgba(0, 132, 255, 0.5); */
    border-radius: 10px 10px 10px 10px;
    position: relative;
    transition: 0.5s;
    color: white;
  }

  nav>a:hover {
    width: auto;
    background-color: transparent;
    border-radius: 10px 10px 10px 10px;
    transition: 0.5s;
  }

  /* Hide the span text in mobile devices */
  nav a span {
    display: none;
  }

  nav a:hover svg {
    opacity: 1; /* Show the icons on hover */
  }
}