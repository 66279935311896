.slider {
  /* padding: auto; */
  max-width: 1000px;
  max-height: 600px;
  border-radius: 10px;
  overflow: hidden;
  /* display: flex; */
}

.slides {
  width: 500%;
  max-height: 600px;
  display: flex;
  position: relative;
}

.slides input {
  display: none;
}

.slide {
  width: 20%;
  transition: 2s;
}

.slide img {
  width: 100%;
  height: 100%;
}

/* css for manual navigation */
.navigation-manual {
  position: absolute;
  width: 1000px;
  display: flex;
  justify-content: center;
  margin-top: 550px;
  /* bottom: 8%; */
  /* top: 80%; */
}


.manual-btn {
  border: 2px solid #ff00c8;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}

.manual-btn:not(:last-child) {
  margin-right: 40px;
}

.manual-btn:hover {
  background: #184166;
}

#radio1:checked ~ .first {
  margin-left: 0;
}

#radio2:checked ~ .first {
  margin-left: -20%;
}

#radio3:checked ~ .first {
  margin-left: -40%;
}

#radio4:checked ~ .first {
  margin-left: -60%;
}
#radio5:checked ~ .first {
  margin-left: -80%;
}

/* css for auto navigation */
.navigation-auto {
  position: fixed;
  display: flex;
  width: 1000px;
  justify-content: center;
  /* margin-top: 460px; */
}

.navigation-auto div {
  border: 2px solid #184166;
  padding: 5px;
  border-radius: 10px;
  transition: 1s;
}

.navigation-auto div:not(:last-child) {
  margin-right: 40px;
}

#radio1:checked ~ .navigation-auto .auto-btn1 {
  background: #184166;
}

#radio2:checked ~ .navigation-auto .auto-btn2 {
  background: #184166;
}

#radio3:checked ~ .navigation-auto .auto-btn3 {
  background: #184166;
}

#radio4:checked ~ .navigation-auto .auto-btn4 {
  background: #184166;
}

#radio5:checked ~ .navigation-auto .auto-btn5 {
  background: #184166;
}

/* ==== Media Queries (Medium Devices) ==== */
@media screen and (max-width: 1024px) {
  .slider {
    max-width: 800px;
    max-height: 400px;
  }

  .slides {
    width: 500%;
    height: 100%;
  }

  .slide {
    width: 20%;
  }

  /* .slide input {
    display: none;
  } */



  .slide img {
    width: 100%;
    height: 100%;
    /* object-fit: fill; Ensure the entire image fits */
  }

  .navigation-manual{
    
  }
}

/* ==== Media Queries (Medium Devices) ==== */
@media screen and (max-width: 600) {
}
