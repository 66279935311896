about {
  height: 100vh;
  padding-top: 4rem;
  overflow: hidden;
  align-items: center;
}

.about {
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #21221f; */
  padding: 1rem;
  border-radius: 10px;
  margin: 2rem;
}

.about h3 {
  font-weight: 900;
  font-size: x-large;
}

.about h4 {
  font-weight: 900;
  font-size: large;
}

.about_with_everything {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* background-color: #759113; */
  padding-top: 4rem;
  border-radius: 10px;
  margin: 2rem;
  /* width: 100%; */
}

.row_about_me_n_resume {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  /* background-color: #184166; */
  border-radius: 10px 10px 10px 10px;
  height: 4rem;
  max-height: 4rem;
  width: 100%;
}

.about_me {
  display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(255, 255, 255, 0.1); */
    border-radius: 10px;
    width: 20rem;
}

.resume {
  /* display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: 10rem; */
  display: block;
}

.resume a[role="button"]:not([href]) {
	cursor: pointer;
	color: white;
}

.row_about_myself {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: #6fafe7; */
  padding: 1rem;
  border-radius: 10px 10px 10px 10px;
  margin: 10px;
  /* height: 100%; */
  width: 100%;
}

.row {
  display: block;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  /* background-color: rgba(255, 255, 255, 0.1); */
  padding: 1rem;
  border-radius: 10px 10px 10px 10px;
  /* margin: 10px; */
  width: 100%;
}

.row_header {
  display: block;
  /* justify-content: center; */
  /* align-items: center; */
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: 15rem;
  text-align: left;
  padding-left: 1rem;
  font-weight: 900;
  max-height: 4rem;
}

.row_desc {
  display: block;
  /* flex-direction: column; */
  /* justify-content: left; */
  /* align-items: flex-start; */
  border-radius: 10px;
  /* background-color: black; */
  text-align: left;
  /* margin: 10px; */
}

.row_work_experiences {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #6fafe7;
  padding: 1rem;
  border-radius: 10px 10px 10px 10px;
  margin: 10px;
  height: 100%;
  width: 100%;
}

.card {
  margin: 1rem;
  width: inherit;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.1);
  border: 10px #31c5d7;
  transition: width 2s, background-color 1s, box-shadow 1s;
  border-radius: 10px;
  color: white;
  text-align: left;
}

.card:hover {
  box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2);
  background-color: transparent;
  /* color: white; */
}

.card-header {
  font-size: 1.5rem;
  background-color: rgba(0,0,0,.1);
  border-radius: 10px 10px 0 0;
  /* width: 100%; */
  padding: 5px;
}

.card p {
  padding: 5px;
}

.card a{
  text-decoration: none;
  color: black;
}

.card li{
  list-style:circle;
}