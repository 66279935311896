projects {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    align-items: center;
}

.projects {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #21221f; */
    padding: 1rem;
    border-radius: 10px;
    margin: 2rem;
}

.projects h3 {
    font-weight: 900;
    font-size: x-large;
}

.projects_all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* background-color: #759113; */
    padding-top: 4rem;
    border-radius: 10px;
    margin: 2rem;
    /* width: 100%; */
}

.project_heading {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(255, 255, 255, 0.1); */
    border-radius: 10px;
    width: 20rem;
}

.project_content {
    display: block;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    /* background-color: rgba(255, 255, 255, 0.1); */
    /* padding: 2rem; */
    border-radius: 10px 10px 10px 10px;
    /* margin: 10px; */
    /* width: 100%; */
}

.card {
    margin: 1rem;
    width: inherit;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.1);
    border: 10px #31c5d7;
    transition: width 2s, background-color 1s, box-shadow 1s;
    border-radius: 10px;
    color: white;
    text-align: left;
}

.card:hover {
    box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2);
    background-color: transparent;
    /* color: white; */
}

.card-header {
    font-size: 1.5rem;
    background-color: rgba(0,0,0,.1);
    border-radius: 10px 10px 0 0;
    /* width: 100%; */
    padding: 5px;
}

.card p {
    padding: 5px;
}

.card a{
    text-decoration: none;
    color: #31c5d7;
}

.card a :hover{
    color: white;
}

/* .card li{
    list-style:circle;
  } */