home {
  height: 100vh;
  padding-top: 5rem;
  overflow: hidden;
  align-items: center;
}

.home_container {
  text-align: center;
  height: 100%;
  position: relative;
  /* background-color: rgba(20, 20, 20, 0.5); */
  border-radius: 15px 15px 15px 15px;
}

/* ==== Scroll Down ==== */
/* .scroll_down {
    position: absolute;
    bottom: 5 rem;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
} */

.row_intro {
  padding-top: 2rem;
  /* padding: 2%; */
  color: #fff;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  height: 100vh;
}

.col_name_photo {
  display: block;
  column-width: 30%;
  /* flex-basis: 35%; */
  width: 25rem;
  padding-top: 2rem;
  /* background-color: rgba(255, 255, 255, 0.1); */
  border-radius: 10px 10px 10px 10px;
}

.col_name_photo img {
  margin: auto;
  max-width: 300px;
  max-height: 500px;
  border-radius: 10px 10px 10px 10px;
  max-width: 300px;
  max-height: 500px;
  padding-left: 1%;
  border-radius: 10px 10px 10px 10px;
  
}

.col_intro {
  /* flex-basis: 65%; */
  margin: 3rem;
  /* padding: 2rem; */
  /* background-color: rgba(255, 255, 255, 0.1); */
  border-radius: 10px 10px 10px 10px;
  height: 100%;
  /* width: 100%; */
  flex: 1;
  display: flex;
  flex-direction: column;
}

.col_intro_with_scroll_btn{
  margin: auto;
  /* padding: 2rem; */
  /* background-color: rgba(255, 255, 255, 0.1); */
  border-radius: 10px 10px 10px 10px;
  height: 500px;
  width: 500px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.col_intro .intro_header {
  /* margin: 1rem; */
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px 10px 10px 10px;
  max-width: 10rem;
  max-height: fit-content;
}

.col_intro .intro_desc {
  /* background-color: rgba(255, 255, 255, 0.1); */
  border-radius: 10px 10px 10px 10px;
  /* flex: 1; */
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  text-justify: inter-word;

  /* overflow-y: scroll; */
  /* padding: 16px; */
  /* scrollbar-width: none; */
  /* padding: 2rem; */
}

.scroll_button{
  /* flex: 1;
  flex-direction: column; */
  display: block;
}

.scroll_button span[role="button"]:not([href]) {
	cursor: pointer;
	color: white;
}

.row_photos {
  padding: 3%;
  color: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 100vh;
  box-sizing: border-box; /* add box-sizing property */
}

.col_photo_loop {
  margin: 1%; /* reduce margin to 1% */
  width: auto; /*set width to fit two children per row*/
  box-sizing: border-box; /* add box-sizing property */
  display: flex;
  align-items: center;
}

/* ==== Media Queries (Medium Devices) ==== */
@media screen and (max-width: 1024px) {
  .home_container {
    background-color: rgba(18, 144, 182, 0.2);
  }
  .row_intro {
    flex-direction: column;
    height: 100%;
  }
  .col_name_photo {
    margin: 0;
    padding: 0;
  }
  .col_intro {
    margin: 2rem;
    padding: 0;
  }
  .row_photos {
    flex-direction: row;
    height: auto;
    width: auto;
  }
  .col_photo_loop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    /* width: auto; */
  }
}

/* ==== Media Queries (Small Devices) ==== */
@media screen and (max-width: 600) {
}
